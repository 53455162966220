// project import
// import pages from './pages';
// import dashboard from './dashboard';
// import utilities from './utilities';
// import support from './support';
// import check from './check';
import AdminPage from './AdminPage';
import StudentPage from './StudentPage';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [AdminPage, StudentPage]
};

export default menuItems;
