import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export default function ScholarshipAgreementDialog({ open, handleClose }) {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disablebackdropclick="true">
      <DialogTitle color="black">장학금 신청</DialogTitle>
      <DialogContent>
        <Typography id="alert-dialog-description1" color="grey">
          장학금 신청을 완료 하시겠습니까?
        </Typography>
        <Typography id="alert-dialog-description2" color="grey">
          신청 후 취소는 불가합니다.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)}>확인</Button>
        <Button onClick={() => handleClose(false)} autoFocus>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
}
