import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  styled,
  alpha,
  InputBase
} from '@mui/material';
import CustomPagination from 'components/pagination';
import SearchIcon from '@mui/icons-material/Search';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const columns = [
  { id: 'studentNumber', label: '학번', minWidth: 100, align: 'center' },
  { id: 'studentName', label: '이름', minWidth: 100, align: 'center' },
  { id: 'email', label: '이메일', minWidth: 100, align: 'center' },
  { id: 'phoneNumber', label: '연락처', minWidth: 100, align: 'center' },
  { id: 'scholarshipNumber', label: '발급번호', minWidth: 100, align: 'center' },
  { id: 'year', label: '년도', minWidth: 100, align: 'center' },
  { id: 'semester', label: '학기', minWidth: 100, align: 'center' },
  { id: 'process', label: '과정', minWidth: 100, align: 'center' },
  { id: 'moduleName', label: '모듈명', minWidth: 100, align: 'center' },
  { id: 'amount', label: '장학금액', minWidth: 100, align: 'center' },
  { id: 'totalGrade', label: '총성적', minWidth: 100, align: 'center' }
];

// 정렬기능을 이용하기 위해 api 문서의 필드와 컬럼 필드명이랑 매핑시킨 객체
const apiFieldMapping = {
  studentNumber: 'm.studentNumber',
  studentName: 'm.memberName',
  email: 'm.email',
  phoneNumber: 'm.cellphone',
  scholarshipNumber: 'scholarshipNumber',
  year: 'year',
  semester: 'semester',
  process: 'md.process',
  moduleName: 'md.moduleName',
  amount: 'amountGrade',
  totalGrade: 'totalGrade'
};

export default function AdminScholarship() {
  const rowsPerPage = 20;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);

  // 기본 정렬 상태 설정
  const [sortConfig, setSortConfig] = useState({ field: 'year', direction: 'desc' });

  const [moduleFilterSelected, setModuleFilterSelected] = useState('');
  const [yearFilterSelected, setYearFilterSelected] = useState('');
  const [amountFilterSelected, setAmountFilterSelected] = useState('');
  const [semesterFilterSelected, setSemesterFilterSelected] = useState('');

  // 모듈 학기를 상수로 선언
  const semesterOptions = [
    { label: '1학기', value: '1' },
    { label: '2학기', value: '2' }
  ];

  const [moduleFilter, setModuleFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);
  const [amountFilter, setAmountFilter] = useState([]);

  const [searchType, setSearchType] = useState('');

  const [studentNumberQuery, setStudentNumberQuery] = useState('');
  const [studentNameQuery, setStudentNameQuery] = useState('');
  const [moduleNameQuery, setModuleNameQuery] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  // 검색 목록 변경 시 상태 업데이트
  const handleSearchChange = (event) => {
    const value = event.target.value;
    switch (searchType) {
      case 10:
        setStudentNameQuery(value);
        break;
      case 20:
        setStudentNumberQuery(value);
        break;
      case 30:
        setModuleNameQuery(value);
        break;
      default:
        return;
    }
  };

  // 정렬 기능: 선택된 컬럼에 대해 정렬순서 토글
  const handleSort = (column) => {
    const field = apiFieldMapping[column];
    const isDesc = sortConfig.field === field && sortConfig.direction === 'desc';
    setSortConfig({ field, direction: isDesc ? 'asc' : 'desc' });
  };

  const handleModuleChange = (event) => setModuleFilterSelected(event.target.value);
  const handleYearChange = (event) => setYearFilterSelected(event.target.value);
  const handleAmountChange = (event) => setAmountFilterSelected(event.target.value);
  const handleSemesterChange = (event) => setSemesterFilterSelected(event.target.value);

  const handleTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage - 1);
    }
  };

  // 모듈 필터링 목록 가져오기
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await http.getWithToken('/api/admin/scholarships/filter');
        if (response.status === 200 && response.data.data.length > 0) {
          const filters = response.data.data[0];
          setModuleFilter(filters.moduleFilter);
          setYearFilter(filters.yearFilter);
          setAmountFilter(filters.amountFilter);
        } else {
          console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
        }
      } catch (error) {
        console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
      }
    };

    fetchModules();
  }, []);

  // 장학증서 리스트 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `/api/admin/confirmed-scholarships?page=${page}&size=${rowsPerPage}`;

        // '선택하지않음' 상태일 때 전체 데이터를 볼 수 있도록 설정
        const moduleFilterParam = moduleFilterSelected || moduleFilter.map((m) => m.id).join(',');
        url += `&moduleFilter=${moduleFilterParam}`;

        const yearFilterParam = yearFilterSelected || yearFilter.join(',');
        url += `&yearFilter=${yearFilterParam}`;

        const semesterFilterParam = semesterFilterSelected || '1,2';
        url += `&semesterFilter=${semesterFilterParam}`;

        const amountFilterParam = amountFilterSelected || amountFilter.join(',');
        url += `&amountFilter=${amountFilterParam}`;

        if (sortConfig.field) {
          url += `&sort=${sortConfig.field},${sortConfig.direction}`;
        }

        // 검색 유형별 url 파라미터 추가
        switch (searchType) {
          case 10:
            if (studentNameQuery) {
              url += `&studentNameQuery=${studentNameQuery}`;
            }
            break;
          case 20:
            if (studentNumberQuery) {
              url += `&studentNumberQuery=${studentNumberQuery}`;
            }
            break;
          case 30:
            if (moduleNameQuery) {
              url += `&moduleNameQuery=${moduleNameQuery}`;
            }
            break;
          default:
            break;
        }

        const response = await http.getWithToken(url);

        setRows(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      } catch (error) {
        alert('데이터 불러오는데 실패하였습니다. 잠시 후 다시 시도해주세요.');
      }
    };

    fetchData();
  }, [
    page,
    rowsPerPage,
    moduleFilterSelected,
    yearFilterSelected,
    semesterFilterSelected,
    amountFilterSelected,
    sortConfig,
    searchType,
    studentNameQuery,
    studentNumberQuery,
    moduleNameQuery,
    moduleFilter,
    yearFilter,
    amountFilter
  ]);

  const handleExportToExcel = async () => {
    try {
      const response = await http.getWithToken('/api/admin/scholarships/execl', {}, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'module_scholarships.xlsx');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      alert('다운로드 중 오류가 발생했습니다. 잠시 후에 다시 시도해주세요');
    }
  };

  // 검색에 모듈 검색 기능을 추가함으로써 조건문이 추가되어 외부로 꺼냄
  let inputValue = '';
  switch (searchType) {
    case 10:
      inputValue = studentNameQuery;
      break;
    case 20:
      inputValue = studentNumberQuery;
      break;
    case 30:
      inputValue = moduleNameQuery;
      break;
    default:
      inputValue = '';
      break;
  }

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', width: '95%' }}>
          <Box sx={{ width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ width: '13%' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.2 }} id="demo-simple-select-label">
                  검색 유형
                </InputLabel>
                <Select value={searchType} label="Age" onChange={handleTypeChange}>
                  <MenuItem value="">선택하지 않음</MenuItem>
                  <MenuItem value={10}>이름</MenuItem>
                  <MenuItem value={20}>학번</MenuItem>
                  <MenuItem value={30}>모듈명</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="검색하기…"
                  inputProps={{ 'aria-label': 'search' }}
                  value={inputValue}
                  onChange={handleSearchChange}
                />
              </Search>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '45%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Box sx={{ width: '45%', marginRight: '16px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.25 }} id="module-filter-label">
                  모듈
                </InputLabel>
                <Select value={moduleFilterSelected} label="Module" onChange={handleModuleChange}>
                  <MenuItem value="">선택하지 않음</MenuItem>
                  {moduleFilter.map((module) => (
                    <MenuItem key={module.id} value={module.id}>
                      {module.moduleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '45%', marginRight: '16px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.25 }} id="year-filter-label">
                  년도
                </InputLabel>
                <Select value={yearFilterSelected} label="Year" onChange={handleYearChange}>
                  <MenuItem value="">선택하지 않음</MenuItem>
                  {yearFilter.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '45%', marginRight: '16px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.25 }} id="semester-select-label">
                  학기
                </InputLabel>
                <Select
                  labelId="semester-select-label"
                  id="semester-select"
                  value={semesterFilterSelected}
                  label="학기"
                  onChange={handleSemesterChange}
                >
                  <MenuItem value="">선택하지않음</MenuItem>
                  {semesterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '65%' }} style={{ marginRight: '10px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.25 }} id="amount-filter-label">
                  장학금액
                </InputLabel>
                <Select value={amountFilterSelected} label="Amount" onChange={handleAmountChange}>
                  <MenuItem value="">선택하지 않음</MenuItem>
                  {amountFilter.map((amount) => (
                    <MenuItem key={amount} value={amount}>
                      {amount}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', width: '90%' }}>
              <Button onClick={handleExportToExcel} variant="contained">
                엑셀로 추출하기
              </Button>
            </Box>
          </Box>
        </Box>
        <Paper sx={{ width: '95%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 1200 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#bfbfbf' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, cursor: 'pointer' }}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                      {sortConfig.field === apiFieldMapping[column.id] ? (sortConfig.direction === 'asc' ? ' 🔼' : ' 🔽') : ''}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                      현재 목록이 비어있습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.studentNumber}-${row.moduleName}-${row.year}-${row.semester}`}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'process') {
                            let course;
                            if (value === 'LOW') {
                              course = '초급';
                            } else if (value === 'MIDDLE') {
                              course = '중급';
                            } else if (value === 'HIGH') {
                              course = '고급';
                            } else {
                              course = value;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {course}
                              </TableCell>
                            );
                          } else if (column.id === 'amount') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value !== null ? `${value.toLocaleString()} 원` : ''}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CustomPagination count={totalPages} page={page} onChange={handleChangePage} />
      </Box>
    </>
  );
}
