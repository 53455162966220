import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Box, Button, Tooltip, IconButton } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ScholarshipAgreementDialog from 'components/scholarshipPopup';
import CertificateCancelPopup from 'components/certificateCancelPopup';
import useDialogToggle from 'pages/authentication/hooks/useDialogToggle';
import { Http } from 'api/http/http';
import { useDispatch } from 'react-redux';
import { logout } from 'store/reducers/auth';

const columns = [
  { id: 'process', label: '과정', minWidth: 100, align: 'center' },
  { id: 'moduleName', label: '모듈명', minWidth: 100, align: 'center' },
  { id: 'certificateNumber', label: '발급번호', minWidth: 100, align: 'center' },
  { id: 'date', label: '신청일자', minWidth: 100, align: 'center' },
  { id: 'certificateStatus', label: '상태', minWidth: 100, align: 'center' },
  { id: 'action', label: 'Action', minWidth: 100, align: 'center' }
];

export default function StudentCertificate() {
  const [rows, setRows] = useState([]);
  const { open: openScholarshipDialog, handleClickOpen, handleClose: handleCloseDialog } = useDialogToggle();
  const { open: openCancelDialog, handleClickOpen: handleClickOpenCancelDialog, handleClose: handleCloseCancelDialog } = useDialogToggle();
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.getWithToken('/api/certificates');
        const data = response.data.data
          .map((item) => ({
            process: item.process,
            moduleName: item.moduleName,
            certificateNumber: item.certificateNumber,
            date: item.date,
            certificateStatus: item.certificateStatus,
            scholarshipApplied: item.scholarshipApplied
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        setRows(data);
      } catch (error) {
        console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
      }
    };
    fetchData();
  }, []);

  // 장학금 버튼 클릭 후 닫을 때의 핸들러: 팝업의 확인 버튼 클릭하면 신청 완료(버튼 비활성화), 취소 버튼 클릭하면 신청x
  const handleClose = async (confirmed) => {
    if (confirmed) {
      try {
        await http.postWithToken('/api/scholarships', { moduleName: selectedRow.moduleName });
        setRows(rows.map((item) => (item.moduleName === selectedRow.moduleName ? { ...item, scholarshipApplied: true } : item)));
      } catch (error) {
        alert('장학금 신청 기간이 아닙니다.');
      }
    }
    handleCloseDialog();
  };

  // 이수증 발급 취소 팝업에서 확인을 눌렀을 때의 핸들러: 확인 버튼 누르면 해당 모듈 신청 취소, 취소 버튼 누르면 그대로
  const handleCancel = async (confirmed) => {
    if (confirmed) {
      try {
        await http.deleteWithToken('/api/certificates', { moduleName: selectedRow.moduleName });
        setRows(rows.filter((item) => item !== selectedRow));
      } catch (error) {
        alert('이수증 삭제에 실패하였습니다. 다시 시도해주세요.');
      }
    }
    handleCloseCancelDialog();
  };

  // 이수증 발급, 취소, 장학금 신청 버튼 클릭 시
  const handleClick = async (row, actionType) => {
    switch (actionType) {
      case 'cancel':
        setSelectedRow(row);
        handleClickOpenCancelDialog();
        break;

      case 'issue':
        try {
          const responsePdf = await http.getWithToken(
            `/api/certificates/download?moduleName=${row.moduleName}`,
            {},
            { responseType: 'blob' }
          );
          const file = new Blob([responsePdf.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '이수증 다운로드 하기', 'height=1000, width=800');
        } catch (error) {
          alert('이수증 pdf 다운로드 실패하였습니다. 잠시 후 다시 시도해주세요.');
        }
        break;

      case 'scholarship':
        setSelectedRow(row);
        handleClickOpen();
        break;

      default:
        console.log('Unknown action');
    }
  };

  // certificateStatus 따라 어떤 버튼이 action에 나타날 지
  const getActionButtons = (row) => {
    if (row.certificateStatus === 'WAIT') {
      return (
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" size="small" onClick={() => handleClick(row, 'cancel')}>
            신청취소
          </Button>
        </Box>
      );
    } else if (row.certificateStatus !== 'WAIT') {
      if (row.certificateStatus === 'APPROVAL') {
        return (
          <Box display="flex" justifyContent="end" position="relative" right="30px">
            {/* <Button variant="contained" color="primary" size="small" onClick={() => handleClick(row, 'issue')}>
              이수증발급
            </Button> */}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              // style={{ marginLeft: '10px' }}
              onClick={() => handleClick(row, 'scholarship')}
              disabled={row.scholarshipApplied}
            >
              장학금신청
            </Button>
          </Box>
        );
      } else if (row.certificateStatus === 'REFUSAL') {
        return (
          <Box display="flex" justifyContent="flex-end" position="relative" right="110px">
            <Tooltip title="이수 조건 확인 후 교과목 이수현황 페이지에서 재신청해 주시기를 바랍니다.">
              <IconButton>
                <ErrorOutlineRoundedIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Paper sx={{ width: '85%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#bfbfbf' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                      현재 목록이 비어있습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={`${index}-${row.moduleName}`}>
                      {columns.map((column) => {
                        let value;
                        if (column.id === 'certificateStatus') {
                          if (row[column.id]) {
                            if (row.certificateStatus === 'APPROVAL') {
                              value = '승인';
                            } else if (row.certificateStatus === 'REFUSAL') {
                              value = '거절';
                            } else if (row.certificateStatus === 'WAIT') {
                              value = '이수심사중';
                            }
                          }
                        } else if (column.id === 'action') {
                          value = getActionButtons(row);
                        } else if (column.id === 'date') {
                          let date = new Date(row[column.id]);
                          let formattedDate =
                            date.getFullYear() +
                            '-' +
                            (date.getMonth() + 1).toString().padStart(2, '0') +
                            '-' +
                            date.getDate().toString().padStart(2, '0') +
                            ' ' +
                            date.getHours().toString().padStart(2, '0') +
                            ':' +
                            date.getMinutes().toString().padStart(2, '0') +
                            ':' +
                            date.getSeconds().toString().padStart(2, '0');

                          value = formattedDate;
                        } else if (column.id === 'process') {
                          if (row[column.id] === 'LOW') {
                            value = '초급';
                          } else if (row[column.id] === 'MIDDLE') {
                            value = '중급';
                          } else if (row[column.id] === 'HIGH') {
                            value = '고급';
                          } else {
                            value = row[column.id];
                          }
                        } else {
                          value = row[column.id];
                        }
                        return (
                          <TableCell key={`${row.moduleName}-${column.id}`} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <ScholarshipAgreementDialog open={openScholarshipDialog} handleClose={handleClose} />
      <CertificateCancelPopup open={openCancelDialog} handleClose={handleCancel} />
    </>
  );
}
