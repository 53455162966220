import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

function SubjectCard({ title, currentModule, selected, addLecture, wholeSubjects, completedSubjects }) {
  const [isClicked, setIsClicked] = useState(false);
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(false);

  const lectureCredit = title.includes('2학점') ? 2 : 3;

  useEffect(() => {
    const newChanged = selected.changed;
    if (newChanged !== changed && currentModule !== selected.module) {
      setIsClicked(false);
    }
    setChanged(newChanged);
  }, [selected]);

  const getSubjectCode = (data, userSubjectName) => {
    let subjectCode;
    data.some((item) => {
      const foundSubject = item.subjectList.find((subject) => subject.subjectName === userSubjectName);
      if (foundSubject) {
        subjectCode = foundSubject.subjectGroupId;
        return true;
      }
    });
    return subjectCode;
  };

  const isCompleted = (data = null, userSubjectCode) => {
    if (data) {
      return data.some((subject) => subject.subjectGroupId === userSubjectCode && subject.isAvailableUseCertificate === true);
    }
    return false;
  };

  const subjectName = title.includes('2학점') ? title.replace('(2학점)', '').trim() : title;
  const subjectCode = getSubjectCode(wholeSubjects, subjectName);
  const isCompletedSubject = isCompleted(completedSubjects, subjectCode);

  const handleConfirm = () => {
    setOpen(false);

    const newIsClicked = !isClicked;
    setIsClicked(newIsClicked);

    addLecture((prevState) => ({
      selectedLectures: [title],
      module: currentModule,
      changed: !prevState.changed,
      credit: lectureCredit
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = () => {
    if (selected.module !== '' && currentModule !== selected.module) {
      setOpen(true);
    } else {
      const newIsClicked = !isClicked;

      addLecture((prevState) => ({
        ...prevState,
        module: currentModule
      }));

      if (newIsClicked) {
        if (selected.credit + lectureCredit > 13) {
          alert('학점은 13학점을 초과할 수 없습니다');
        } else {
          setIsClicked(newIsClicked);
          addLecture((prevState) => ({
            ...prevState,
            selectedLectures: [...prevState.selectedLectures, title],
            credit: prevState.credit + lectureCredit
          }));
        }
      } else {
        setIsClicked(newIsClicked);
        addLecture((prevState) =>
          prevState.credit - lectureCredit
            ? {
                ...prevState,
                selectedLectures: [...prevState.selectedLectures].filter((lecture) => lecture !== title),
                credit: prevState.credit - lectureCredit
              }
            : { selectedLectures: [], module: '', changed: false, credit: 0 }
        );
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'다른 모듈의 과목을 선택하시나요?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            다른 모듈 과목을 선택하면 기존 모듈에서 선택했던 과목이 취소 됩니다. 그래도 계속 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} autoFocus>
            예
          </Button>
          <Button onClick={handleClose}>아니오</Button>
        </DialogActions>
      </Dialog>
      <Card
        onClick={isCompletedSubject ? handleCardClick : null}
        sx={{
          backgroundColor: isCompletedSubject ? (isClicked ? 'primary.main' : 'white') : 'grey.300',
          color: isCompletedSubject ? (isClicked ? 'white' : 'black') : 'grey.500',
          cursor: isCompletedSubject ? 'pointer' : 'default'
        }}
      >
        <CardContent>
          <Typography variant="body1" component="div">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default SubjectCard;
