// assets
import { HomeOutlined, SettingOutlined, AuditOutlined, LoginOutlined, IdcardOutlined } from '@ant-design/icons';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// icons
const icons = {
  HomeOutlined,
  SettingOutlined,
  AuditOutlined,
  LoginOutlined,
  IdcardOutlined,
  PersonSearchIcon
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const AdminPage = {
  id: 'adminPage',
  title: 'AdminPage',
  type: 'group',
  children: [
    {
      id: 'adminHome',
      title: '홈',
      type: 'item',
      url: '/adminHome',
      icon: icons.HomeOutlined
    },
    {
      id: 'adminModule',
      title: '모듈 및 교과목관리',
      type: 'item',
      url: '/adminModule',
      icon: icons.SettingOutlined
    },
    {
      id: 'admincertificate-page',
      title: '이수증 관리',
      type: 'item',
      url: '/admin-certificate-management',
      icon: icons.AuditOutlined
    },
    {
      id: 'admincertificateadd-page',
      title: '이수증 승인(추가)',
      type: 'item',
      url: '/admin-certificate-add-management',
      icon: icons.AuditOutlined
    },
    {
      id: 'adminscholarship certificate-page',
      title: '장학증서 관리',
      type: 'item',
      url: '/admin-scholarship-certificate-management',
      icon: icons.IdcardOutlined
    },
    {
      id: 'adminscholarship certificateadd-page',
      title: '장학금 승인(추가)',
      type: 'item',
      url: '/admin-scholarship-certificate-add-management',
      icon: icons.IdcardOutlined
    },
    {
      id: 'adminStudentInfo',
      title: '학생 정보 조회',
      type: 'item',
      url: '/student-info',
      icon: icons.PersonSearchIcon
    }
  ]
};

export default AdminPage;
