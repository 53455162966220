// material-ui
import {
  Container,
  Card,
  Typography,
  Box,
  CardHeader,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Divider,
  styled,
  alpha,
  InputBase,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Http } from 'api/http/http';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AdminEditStudentInfo from './AdminEditStudentInfo';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.9),
  boxShadow: theme.shadows[1],
  width: '25%',
  [theme.breakpoints.up('sm')]: {
    width: '25%'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const splitModulesData = (modulesData) => {
  let issuedModules = [];
  let applyingModules = [];

  modulesData.forEach((module) => {
    if (module.certificateStatus === 'APPROVAL') issuedModules = [...issuedModules, module.moduleName];

    if (module.certificateStatus === 'WAIT') applyingModules = [...applyingModules, module.moduleName];
  });

  return { issuedModules, applyingModules };
};

const splitScholarshipsData = (scholarshipsData) => {
  let issuedScholarships = [];
  let applyingScholarships = [];

  scholarshipsData.forEach((scholarship) => {
    if (scholarship.isConfirmed && scholarship.amount) issuedScholarships = [...issuedScholarships, scholarship.moduleName];

    if (!scholarship.isConfirmed) applyingScholarships = applyingScholarships = [...applyingScholarships, scholarship.moduleName];
  });

  return { issuedScholarships, applyingScholarships };
};


const getSubjectInfo = (subjectCode, allSubjectData, isAvailableUseCertificate, subjectName) => {
  let subjectInfo = null;
  allSubjectData.forEach(({ moduleName, process, subjectList }) => {
    const matchingSubject = subjectList.find((subject) => subject.subjectGroupId === subjectCode);
    if (matchingSubject) {
      const mappingProcess = {
        HIGH: '고급',
        MIDDLE: '중급',
        LOW: '초급'
      };
      const mappingUsed = {
        true: '모듈 이수에 사용 가능',
        false: '불가능 (타 모듈에 이미 사용됨)'
      };
      subjectInfo = {
        moduleName: moduleName,
        process: mappingProcess[process],
        subjectName: subjectName,
        available: mappingUsed[isAvailableUseCertificate]
      };
    }
  });

  return subjectInfo; // 결과 반환
};

const getSubjectInfoList = (subjectData, allSubjectData) => {
  let subjectInfoList = [];
  console.log('subject Data', subjectData);
  subjectData.forEach(({ subjectGroupId, isAvailableUseCertificate, subjectName }) => {
    const subjectData = getSubjectInfo(subjectGroupId, allSubjectData, isAvailableUseCertificate, subjectName);
    if (subjectData) subjectInfoList = [...subjectInfoList, subjectData];
  });
  return subjectInfoList;
};

const AdminStudentInfo = () => {
  const [studentInfo, setStudentInfo] = useState({
    name: null,
    issuedModules: [],
    applyingModules: [],
    issuedScholarships: [],
    applyingScholarships: [],
    subjectInfoList: []
  });
  const [studentNumber, setStudentNumber] = useState(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/adminLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUserData = async () => {
    try {
      const urls = [
        `/api/certificates/${studentNumber}`,
        `/api/scholarships/${studentNumber}`,
        `/api/subjects/${studentNumber}`,
        '/api/modules',
        `/api/students/${studentNumber}`
      ];
      const [modulesRawData, scholarshipsRawData, subjectsRawData, allSubjectRawData, rawNameData] = await Promise.all(
        urls.map((url) => http.getWithToken(url))
      );

      const modulesData = modulesRawData.data.data;
      const scholarshipsData = scholarshipsRawData.data.data;
      const subjectsData = subjectsRawData.data.data;
      const allSubjectData = allSubjectRawData.data.data;
      const nameData = rawNameData.data.data[0];
      // console.log(subjectsData, allSubjectData);
      const subjectInfoList = getSubjectInfoList(subjectsData, allSubjectData);
      const { issuedModules, applyingModules } = splitModulesData(modulesData);
      const { issuedScholarships, applyingScholarships } = splitScholarshipsData(scholarshipsData);

      setStudentInfo((prev) => ({
        ...prev,
        name: nameData,
        issuedModules: issuedModules,
        applyingModules: applyingModules,
        issuedScholarships: issuedScholarships,
        applyingScholarships: applyingScholarships,
        subjectInfoList: subjectInfoList
      }));
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setStudentNumber(input);
  };

  const handleCheckClick = () => {
    fetchUserData();
  };

  return (
    <Container sx={{ width: '100%' }}>
      <Box sx={{ marginBottom: '3rem', display: 'flex', gap: '1rem' }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder="학번으로 조회하기" inputProps={{ 'aria-label': 'search' }} onChange={handleInputChange} />
        </Search>
        <Button variant="contained" onClick={handleCheckClick}>
          조회
        </Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2, marginBottom: 2 }}>
        {studentInfo.name ? (
          <>
            <Typography variant="h1">{studentInfo.name}님의 이수 현황</Typography>
            <Button variant="contained" color="primary" sx={{ marginLeft: 'auto' }} onClick={handleOpen}>
              학생 계정 설정
            </Button>
            <AdminEditStudentInfo open={open} onClose={handleClose} studentId={studentNumber} />
          </>
        ) : (
          <Typography variant="h1">학번으로 학생을 조회해주세요</Typography>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4, flexWrap: 'wrap', marginY: 5 }}>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="발급 받은 이수증" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'primary.main' }}
            >{`${studentInfo.issuedModules.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.issuedModules.length === 0 ? (
                <ListItemButton>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItemButton>
              ) : (
                studentInfo.issuedModules.map((module) => (
                  <ListItemButton key={module}>
                    <ListItemText primary={module} />
                  </ListItemButton>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="발급 받은 장학증서" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'primary.main' }}
            >{`${studentInfo.issuedScholarships.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.issuedScholarships.length === 0 ? (
                <ListItemButton>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItemButton>
              ) : (
                studentInfo.issuedScholarships.map((scholarship) => (
                  <ListItemButton key={scholarship}>
                    <ListItemText primary={scholarship} />
                  </ListItemButton>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="신청 중인 이수증" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'warning.main' }}
            >{`${studentInfo.applyingModules.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.applyingModules.length === 0 ? (
                <ListItemButton>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItemButton>
              ) : (
                studentInfo.applyingModules.map((module) => (
                  <ListItemButton key={module}>
                    <ListItemText primary={module} />
                  </ListItemButton>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="신청 중인 장학증서" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'warning.main' }}
            >{`${studentInfo.applyingScholarships.length}건`}</Typography>
          </Box>
          <CardContent>
            {studentInfo.applyingScholarships.length === 0 ? (
              <ListItemButton>
                <ListItemText primary={'현재 목록이 비어있습니다.'} />
              </ListItemButton>
            ) : (
              studentInfo.applyingScholarships.map((scholarship) => (
                <ListItemButton key={scholarship}>
                  <ListItemText primary={scholarship} />
                </ListItemButton>
              ))
            )}
          </CardContent>
        </Card>
      </Box>
      <Paper sx={{ width: '100%%' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }} colSpan={4} align="center">
                  교과목 이수 현황
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">이수한 교과목 명</TableCell>
                <TableCell align="center">과정 </TableCell>
                <TableCell align="center">모듈</TableCell>
                <TableCell align="center">활용가능여부</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentInfo.subjectInfoList.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }} colSpan={4}>
                    현재 목록이 비어있습니다.
                  </TableCell>
                </TableRow>
              ) : (
                studentInfo.subjectInfoList.map((subjectInfo) => (
                  <TableRow key={subjectInfo.subjectName}>
                    <TableCell align="center">{subjectInfo.subjectName}</TableCell>
                    <TableCell align="center">{subjectInfo.process}</TableCell>
                    <TableCell align="center">{subjectInfo.moduleName}</TableCell>
                    <TableCell align="center">{subjectInfo.available}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default AdminStudentInfo;
