import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import MainCard from '../MainCard';

const Breadcrumbs = ({ navigation, title, ...others }) => {
  const location = useLocation();
  const [item, setItem] = useState();

  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === 'item') {
          if (location.pathname === collapse.url) {
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu);
      }
      return false;
    });
  });

  if (location.pathname === '/breadcrumbs') {
    location.pathname = '/dashboard/analytics';
  }

  let breadcrumbContent = <Typography />;

  if (item && item.type === 'item' && title) {
    breadcrumbContent = (
      <MainCard border={false} sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item sx={{ mt: 2 }}>
            <Typography variant="h5">{item.title}</Typography>
          </Grid>
        </Grid>
      </MainCard>
    );
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool
};

export default Breadcrumbs;
