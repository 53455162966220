import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute() {
  const { isLoggedIn, role } = useSelector((state) => state.auth); // 로그인 여부

  if (!isLoggedIn) {
    // 사용자가 로그인하지 않은 경우, 로그인 페이지로 리디렉션
    return <Navigate to="/studentLogin" replace />;
  }

  if (isLoggedIn && role === 'admin') {
    return <Navigate to="/adminHome" replace />;
  }

  return <Outlet />; // 로그인한 경우, 해당 라우트의 자식 컴포넌트 렌더링
}
