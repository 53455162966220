// material-ui
import { Box, Typography } from '@mui/material';

// react-redux
import { useSelector } from 'react-redux';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  //redux 상태 활용
  const { role } = useSelector((state) => state.auth);
  const item = role === 'admin' ? menuItem.items[0] : menuItem.items[1];

  // role에 따라서 다른 메뉴가 보이게 변경
  const navGroups =
    item.type === 'group' ? (
      <NavGroup key={item.id} item={item} />
    ) : (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Fix - Navigation Group
      </Typography>
    );

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
