import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project import
import AnimateButton from 'components/@extended/AnimateButton';

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import useToggleFirstPassword from 'pages/authentication/hooks/useToggleFirstPassword';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

export default function StudentPasswordPermute({ open, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });
  const { showPassword, handleClickShowPassword, handleMouseDownPassword } = useToggleFirstPassword();

  // 비밀번호 유효성 검사: 숫자 & 영어(대, 소문자) 조합으로 이뤄지게
  const PasswordSchema = Yup.object().shape({
    password1: Yup.string()
      .min(8, '비밀번호는 최소 8자리 이상이어야 합니다.')
      .max(20, '비밀번호는 최대 20자리 이하여야 합니다.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_!@#$%^&*\-+=?]*$/,
        '비밀번호는 영어와 숫자의 조합이어야 하며, 특수문자는 선택적으로 포함할 수 있습니다.'
      )
      .required('비밀번호를 입력하세요'),
    password2: Yup.string().required('비밀번호 확인이 필요합니다.')
  });

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/studentLogin');
    }
  }, []);

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: false });
      setSubmitting(true);

      const response = await http.postWithToken('/api/password', {
        password: values.password1
      });

      if (response.status === 200) {
        setStatus({ success: true });
        alert('비밀번호 설정이 완료되었습니다. 다시 로그인해주세요');
        // 비밀번호 변경 성공 후, 강제 로그아웃하여 변경된 비밀번호로 다시 로그인
        handleLogout();
        navigate('/studentLogin');
      } else {
        setErrors({ submit: response.data.error });
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
    }
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { width: '600px', maxHeight: '60vh' } }}>
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>비밀번호 변경</DialogTitle>
      <DialogContent sx={{ marginTop: '20px' }}>
        <Formik
          initialValues={{
            password1: '',
            password2: '',
            submit: null
          }}
          validationSchema={PasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, isValid, dirty }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password1-login">비밀번호</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password1 && errors.password1)}
                      id="password1-login"
                      type={showPassword.password1 ? 'text' : 'password'}
                      value={values.password1}
                      name="password1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password1')}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword.password1 ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="변경할 비밀번호를 입력하세요"
                    />
                    {touched.password1 && errors.password1 && (
                      <FormHelperText error id="standard-weight-helper-text-password1-login">
                        {errors.password1}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password2-login">비밀번호 확인</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password2 && errors.password2)}
                      id="password2-login"
                      type={showPassword.password2 ? 'text' : 'password'}
                      value={values.password2}
                      name="password2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password2')}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword.password2 ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="비밀번호를 재입력하세요"
                    />
                    {touched.password2 && errors.password2 && (
                      <FormHelperText error id="standard-weight-helper-text-password2-login">
                        {errors.password2}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {values.password1 && values.password2 && values.password1 !== values.password2 && (
                    <FormHelperText error>비밀번호가 일치하지 않습니다.</FormHelperText>
                  )}
                  <DialogActions>
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={isSubmitting || !isValid || !dirty || values.password1 !== values.password2}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        설정하기
                      </Button>
                    </AnimateButton>
                    <Button onClick={onClose} color="secondary">
                      취소
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
