import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Box,
  DialogContentText
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

const createData = (name, moduleNames, lectureNames) => ({ name, moduleNames, lectureNames });

const getRows = (dataInput) => {
  const courseData = {
    '초급 과정': { moduleNames: [], lectureNames: [] },
    '중급 과정': { moduleNames: [], lectureNames: [] },
    '고급 과정': { moduleNames: [], lectureNames: [] }
  };

  dataInput.forEach((item) => {
    const processName = {
      HIGH: '고급 과정',
      MIDDLE: '중급 과정',
      LOW: '초급 과정'
    }[item.process];

    if (!courseData[processName].moduleNames.includes(item.moduleName)) {
      courseData[processName].moduleNames.push(item.moduleName);
      courseData[processName].lectureNames.push([]);
    }

    const moduleIndex = courseData[processName].moduleNames.indexOf(item.moduleName);
    courseData[processName].lectureNames[moduleIndex] =
      item.subjectList.length > 1
        ? [
            ...courseData[processName].lectureNames[moduleIndex],
            item.subjectList.reduce((acc, subject) => acc + ',' + subject.subjectName, '').substr(1)
          ]
        : item.credit === 2
        ? [...courseData[processName].lectureNames[moduleIndex], item.subjectList[0].subjectName + '(2학점)']
        : [...courseData[processName].lectureNames[moduleIndex], item.subjectList[0].subjectName];
  });

  const rows = Object.entries(courseData).map(([name, data]) => createData(name, data.moduleNames, data.lectureNames));

  return rows;
};

function AdminTable() {
  const [apiData, setApiData] = useState(null);
  const [standardSubjectApiData, setStandardSubjectApiData] = useState(null);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedCourseModules, setSelectedCourseModules] = useState([]);
  const [selectedStandardSubject, setSelectedStandardSubject] = useState('');
  const [lectureNames, setLectureNames] = useState(['']);

  const longText = `여러 모듈에 포함되는 과목이라면, 각 모듈별로 교과목을 모두 추가해주어야합니다`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const fetchData = async () => {
    try {
      const response = await http.getWithToken('/api/modules');
      const rawApiData = response.data.data;
      setApiData(rawApiData);
    } catch (error) {
      alert('데이터를 불러오는 데 실패하였습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const fetchStandardSubjectData = async () => {
    try {
      const response = await http.getWithToken('/api/admin/subjects/drop-down');
      const rawApiData = response.data.data;
      setStandardSubjectApiData(rawApiData);
    } catch (error) {
      alert('데이터를 불러오는 데 실패하였습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  useEffect(() => {
    fetchData();
    fetchStandardSubjectData();
  }, []);

  useEffect(() => {
    if (apiData !== null) {
      const changedRows = getRows(apiData);
      setRows(changedRows);
    }
  }, [apiData]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    const processName = {
      '고급 과정': 'HIGH',
      '중급 과정': 'MIDDLE',
      '초급 과정': 'LOW'
    }[selectedCourse];

    setOpen(false);
    const lectureToAdd = {
      process: processName,
      moduleName: selectedModule,
      standardSubject: selectedStandardSubject,
      subjectName: lectureNames[0]
    };
    console.log(lectureToAdd);

    try {
      await http.postWithToken('/api/admin/subjects', lectureToAdd);
      alert('교과목이 성공적으로 추가되었습니다.');
      fetchData();
      handleClose();
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose();
    }
  };

  const getStandardSubjectList = (moduleName) => {
    let standardSubjectList = [];
    if (standardSubjectApiData) {
      standardSubjectApiData.forEach((data) => {
        if (data.moduleName === moduleName) {
          standardSubjectList = [...new Set([...standardSubjectList, ...data.standardSubject])];
        }
      });
    }
    return standardSubjectList;
  };

  getStandardSubjectList(selectedModule);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    const courseModules = rows.find((row) => row.name === event.target.value).moduleNames;
    setSelectedCourseModules(courseModules);
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const handleStandardLectureNameChange = (event) => {
    setSelectedStandardSubject(event.target.value);
  };

  const handleLectureNameChange = (index) => (event) => {
    const newLectureNames = [...lectureNames];
    newLectureNames[index] = event.target.value;
    setLectureNames(newLectureNames);
  };

  const handleClose = () => {
    setOpen(false);
    setLectureNames(['']);
    setSelectedStandardSubject('');
    setSelectedModule('');
    setSelectedCourse('');
    setSelectedCourseModules([]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button variant="contained" onClick={handleClickOpen}>
          추가하기
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">과정</TableCell>
              <TableCell align="center">모듈명</TableCell>

              <TableCell align="center" colSpan={2}>
                개설 교과목 명
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) =>
              row.moduleNames.flatMap((module, moduleIndex) =>
                row.lectureNames[moduleIndex]
                  .reduce((resultArray, item, itemIndex) => {
                    const chunkIndex = Math.floor(itemIndex / 2);
                    if (!resultArray[chunkIndex]) {
                      resultArray[chunkIndex] = [];
                    }
                    resultArray[chunkIndex].push(item);
                    return resultArray;
                  }, [])
                  .map((lectureGroup, lectureGroupIndex) => (
                    <TableRow key={`${index}-${moduleIndex}-${lectureGroupIndex}`}>
                      {moduleIndex === 0 && lectureGroupIndex === 0 && (
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          rowSpan={row.moduleNames.reduce((sum, _, i) => sum + Math.ceil(row.lectureNames[i].length / 2), 0)}
                        >
                          {row.name}
                        </TableCell>
                      )}
                      {lectureGroupIndex === 0 && (
                        <TableCell align="center" rowSpan={Math.ceil(row.lectureNames[moduleIndex].length / 2)}>
                          <Typography>{module}</Typography>
                        </TableCell>
                      )}
                      {lectureGroup.map((lecture, lectureIndex) => (
                        <TableCell align="center" key={lectureIndex} style={{ wordWrap: 'break-word', width: '24rem' }}>
                          {lecture}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>새 교과목 추가</DialogTitle>

        <DialogContent>
          <Box display={'flex'} alignItems={'center'}>
            <DialogContentText>마이크로모듈 새 교과목을 추가할 수 있습니다 </DialogContentText>
            <Tooltip title={longText}>
              <HelpOutlineIcon sx={{ fontSize: 28, marginLeft: 2 }} color="primary" />
            </Tooltip>
          </Box>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel sx={{ paddingY: 0.3 }} id="course-select-label">
              과정
            </InputLabel>
            <Select labelId="course-select-label" id="course-select" value={selectedCourse} label="과정" onChange={handleCourseChange}>
              {rows.map((row, index) => (
                <MenuItem key={index} value={row.name}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel sx={{ paddingY: 0.3 }} id="module-select-label">
              모듈명
            </InputLabel>
            <Select labelId="module-select-label" id="module-select" value={selectedModule} label="모듈명" onChange={handleModuleChange}>
              {selectedCourseModules.map((module, index) => (
                <MenuItem key={index} value={module}>
                  {module}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel sx={{ paddingY: 0.3 }} id="module-select-label">
              표준교과목명
            </InputLabel>
            <Select
              labelId="module-select-label"
              id="module-select"
              value={selectedStandardSubject}
              label="모듈명"
              onChange={handleStandardLectureNameChange}
            >
              {getStandardSubjectList(selectedModule).map((standardSubject, index) => (
                <MenuItem key={index} value={standardSubject}>
                  {standardSubject}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {lectureNames.map((lectureName, index) => (
            <TextField
              key={index}
              margin="dense"
              name="lectureName"
              label="개설 교과목 명"
              type="text"
              fullWidth
              value={lectureName}
              onChange={handleLectureNameChange(index)}
              style={{ marginTop: 20 }}
              InputLabelProps={{ style: { paddingTop: '3px' } }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>추가</Button>
          <Button onClick={handleClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminTable;
