import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CustomPagination from 'components/pagination';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

const columns = [
  { id: 'checkBox', label: '', minWidth: 50, align: 'center' },
  { id: 'studentNumber', label: '학번', minWidth: 100, align: 'center' },
  { id: 'studentName', label: '이름', minWidth: 100, align: 'center' },
  { id: 'email', label: '이메일', minWidth: 100, align: 'center' },
  { id: 'phoneNumber', label: '연락처', minWidth: 100, align: 'center' },
  { id: 'process', label: '과정', minWidth: 100, align: 'center' },
  { id: 'moduleName', label: '모듈명', minWidth: 100, align: 'center' },
  { id: 'subjectList', label: '교과목명', minWidth: 100, align: 'center' },
  { id: 'certificateNumber', label: '발급번호', minWidth: 100, align: 'center' },
  { id: 'createdDate', label: '신청일자', minWidth: 100, align: 'center' },
  { id: 'action', label: 'action', minWidth: 100, align: 'center' }
];

export default function AdminCertificateAdd() {
  const rowsPerPage = 20;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectListOpen, setSubjectListOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const handleChangePage = (event, newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage - 1);
    }
  };

  // 이수증 리스트 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.getWithToken(`/api/admin/certificates?page=${page}&size=${rowsPerPage}`);
        const sortRows = response.data.data.content
          .map((row) => ({ ...row, certificateStatus: 'WAIT' }))
          .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
        setRows(sortRows);
        setTotalPages(response.data.data.totalPages);
      } catch (error) {
        console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
      }
    };
    fetchData();
  }, [page]);

  // 교과목명 확인 버튼 클릭했을 때, 해당 학생이 신청한 교과목 리스트 모달창 띄우기
  const handleSubjectOpen = (subjectListFromRow) => {
    setSubjectList(subjectListFromRow);
    setSubjectListOpen(true);
  };

  const handleSubjectClose = () => {
    setSubjectListOpen(false);
  };

  // 승인 버튼 눌렀을 때
  const handleApproval = async (row) => {
    try {
      await http.postWithToken('/api/admin/certificates/approval', [
        {
          studentNumber: row.studentNumber,
          moduleName: row.moduleName
        }
      ]);

      alert(`${row.studentNumber}에 대한 승인이 완료되었습니다.`);
      setRows(rows.filter((r) => !(r.studentNumber === row.studentNumber && r.moduleName === row.moduleName)));
    } catch (error) {
      console.log(error);
      alert('승인에 실패하였습니다.', error);
    }
  };

  //'반려' 버튼 눌렀을 때
  const handleReject = async (row) => {
    try {
      await http.postWithToken('/api/admin/certificates/refusal', [
        {
          studentNumber: row.studentNumber,
          moduleName: row.moduleName
        }
      ]);

      alert(`${row.studentNumber}에 대한 거절이 완료되었습니다.`);
      setRows(rows.filter((r) => !(r.studentNumber === row.studentNumber && r.moduleName === row.moduleName)));
    } catch (error) {
      alert('거절에 실패하였습니다.', error);
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckBox = (event, index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  // 전체선택, 선택해제 핸들러
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n, index) => index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // 일괄승인, 일괄반려 핸들러
  const batchProcess = async (type) => {
    if (selected.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    const payload = selected.map((index) => ({
      studentNumber: rows[index].studentNumber,
      moduleName: rows[index].moduleName
    }));
    const url = type === 'approval' ? '/api/admin/certificates/approval' : '/api/admin/certificates/refusal';
    try {
      const response = await http.postWithToken(url, payload);
      alert(`선택된 데이터에 대한 일괄${type === 'approval' ? '승인' : '반려'}이 완료되었습니다.`, response.data);
      setRows(rows.filter((_, index) => !selected.includes(index)));
      setSelected([]);
    } catch (error) {
      alert(`일괄${type === 'approval' ? '승인' : '반려'} 중 오류가 발생했습니다`, error);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="50vh">
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '10px', marginRight: '45px' }}>
          <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={() => batchProcess('approval')}>
            일괄승인
          </Button>
          <Button variant="contained" color="primary" onClick={() => batchProcess('refusal')}>
            일괄반려
          </Button>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#bfbfbf' }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                      현재 목록이 비어있습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={selected.indexOf(index) !== -1}
                        tabIndex={-1}
                        key={index}
                        selected={selected.indexOf(index) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selected.indexOf(index) !== -1} onChange={(event) => handleCheckBox(event, index)} />
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'action') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ marginRight: '5px' }}
                                  onClick={() => handleApproval(row)}
                                >
                                  승인
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => handleReject(row)}>
                                  반려
                                </Button>
                              </TableCell>
                            );
                          } else if (column.id === 'createdDate') {
                            let date = new Date(value);
                            let formattedDate =
                              date.getFullYear() +
                              '-' +
                              (date.getMonth() + 1).toString().padStart(2, '0') +
                              '-' +
                              date.getDate().toString().padStart(2, '0') +
                              ' ' +
                              date.getHours().toString().padStart(2, '0') +
                              ':' +
                              date.getMinutes().toString().padStart(2, '0') +
                              ':' +
                              date.getSeconds().toString().padStart(2, '0');

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {formattedDate}
                              </TableCell>
                            );
                          } else if (column.id === 'process') {
                            let course;
                            if (value === 'LOW') {
                              course = '초급';
                            } else if (value === 'MIDDLE') {
                              course = '중급';
                            } else if (value === 'HIGH') {
                              course = '고급';
                            } else {
                              course = value;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {course}
                              </TableCell>
                            );
                          } else if (column.id === 'subjectList') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button variant="contained" color="primary" onClick={() => handleSubjectOpen(row.subjectList)}>
                                  확인
                                </Button>
                                <Dialog
                                  open={subjectListOpen}
                                  onClose={handleSubjectClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  BackdropProps={{
                                    style: { backgroundColor: 'rgba(255, 255, 255, 0.4)' }
                                  }}
                                  PaperProps={{
                                    elevation: 3,
                                    sx: {
                                      width: '250px'
                                    }
                                  }}
                                >
                                  <DialogTitle style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{'신청 교과목 리스트'}</DialogTitle>
                                  <DialogContent>
                                    <List style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                                      {Array.isArray(subjectList) ? (
                                        subjectList.map((subject, index) => (
                                          <ListItem key={index} style={{ display: 'list-item', marginBottom: '-10px' }}>
                                            <ListItemText primary={subject} />
                                          </ListItem>
                                        ))
                                      ) : (
                                        <ListItem>
                                          <ListItemText primary="신청한 교과목을 확인할 수 없습니다." />
                                        </ListItem>
                                      )}
                                    </List>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleSubjectClose} color="primary">
                                      닫기
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CustomPagination count={totalPages} page={page} onChange={handleChangePage} />
      </Box>
    </>
  );
}
