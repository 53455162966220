import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// project import
// import Logo from './Logo';
// import config from 'config';
import Logo from '../../assets/images/logo/header_full_logo.png';
import { activeItem } from 'store/reducers/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => {
  const { defaultId } = useSelector((state) => state.menu);
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const defaultPath = role === 'admin' ? '/adminHome' : '/studentHome';

  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? defaultPath : to}
      sx={sx}
    >
      {/* <Logo /> */}
      <img style={{ width: '200px' }} src={Logo} alt="지능형로봇사업단로고" />
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
