import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { Http } from 'api/http/http';
import { useDispatch } from 'react-redux';
import { logout } from 'store/reducers/auth';

const AdminEditStudentInfo = ({ open, onClose, studentId }) => {
  const [studentEmail, setStudentEmail] = useState('');
  const [confirmResetPasswordOpen, setConfirmResetPasswordOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/adminLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const handleEmailChange = (event) => {
    setStudentEmail(event.target.value);
  };

  const handleSubmitEmailChange = async () => {
    if (!studentEmail) {
      alert('이메일 주소를 입력해주세요.');
      return;
    }
    try {
      await http.putWithToken(`/api/email/${studentId}`, {
        email: studentEmail
      });
      alert('이메일 주소가 성공적으로 변경되었습니다.');
      onClose();
    } catch (error) {
      alert('이메일 변경 중 오류가 발생했습니다. 다시 시도해주세요.', error);
    } finally {
      setStudentEmail('');
    }
  };

  const handleResetPassword = async () => {
    setConfirmResetPasswordOpen(true);
  };

  const handleConfirmResetPassword = async () => {
    setConfirmResetPasswordOpen(false);
    try {
      await http.postWithToken(`/api/password/${studentId}`);
      alert('학생의 비밀번호가 성공적으로 초기화되었습니다.');
      onClose();
    } catch (error) {
      alert('이미 처리된 상태입니다.', error);
    }
  };

  const handleCancelResetPassword = () => {
    setConfirmResetPasswordOpen(false);
  };

  return (
    <>
      <Dialog open={confirmResetPasswordOpen} onClose={handleCancelResetPassword}>
        <DialogTitle sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>비밀번호 초기화</DialogTitle>
        <DialogContent>비밀번호를 초기화하시겠습니까?</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmResetPassword} color="primary">
            확인
          </Button>
          <Button onClick={handleCancelResetPassword} color="secondary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { width: '600px', maxHeight: '60vh' } }}>
        <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>학생 계정 설정</DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ fontSize: '1rem', fontWeight: 'bold', paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
            이메일 변경
          </DialogTitle>
          <Tooltip title="이메일 변경과 비밀번호 초기화를 동시에 할 경우, 이메일 변경을 먼저 해주세요." placement="bottom">
            <IconButton size="extra-small">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent sx={{ marginTop: '-10px' }}>
          <TextField
            autoFocus
            label="변경할 이메일 주소를 입력해주세요."
            type="email"
            fullWidth
            variant="outlined"
            value={studentEmail}
            onChange={handleEmailChange}
            sx={{
              '& .MuiInputLabel-root': {
                paddingTop: '3px'
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitEmailChange}
            color="primary"
            variant="contained"
            sx={{ marginRight: 'auto', marginLeft: '16px', marginBottom: '15px' }}
          >
            이메일 저장
          </Button>
        </DialogActions>
        <DialogTitle
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            borderTop: '1px solid #e0e0e0',
            width: 'calc(100% - 40px)',
            margin: '0 auto',
            paddingLeft: '6px',
            paddingRight: '8px'
          }}
        >
          비밀번호 초기화
        </DialogTitle>
        <DialogContent sx={{ marginTop: '-10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '6px' }}>현재 학생 계정에 설정된 비밀번호를 초기화합니다.</span>
            <Tooltip
              title="초기화 시 학생의 이메일로 임시 비밀번호가 전송됩니다. 학생은 전송된 비밀번호로 로그인 후 새로운 비밀번호를 설정할 수 있습니다."
              placement="bottom"
            >
              <IconButton size="extra-small">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleResetPassword}
            color="primary"
            variant="contained"
            sx={{ marginRight: 'auto', marginLeft: '16px', marginBottom: '15px', marginTop: '-10px' }}
          >
            비밀번호 초기화
          </Button>
          <Button onClick={onClose} color="secondary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminEditStudentInfo;
