import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Box, Button } from '@mui/material';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

const columns = [
  { id: 'process', label: '과정', minWidth: 100, align: 'center' },
  { id: 'moduleName', label: '모듈명', minWidth: 100, align: 'center' },
  { id: 'year', label: '년도', minWidth: 100, align: 'center' },
  { id: 'semester', label: '학기', minWidth: 100, align: 'center' },
  { id: 'isConfirmed', label: '상태', minWidth: 100, align: 'center' },
  { id: 'action', label: 'Action', minWidth: 100, align: 'center' }
];

export default function StudentScholarship() {
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });
  // 장학증서 리스트 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.getWithToken('/api/scholarships');
        const data = response.data.data.map((item) => ({
          process: item.process,
          moduleName: item.moduleName,
          year: item.year,
          semester: item.semester,
          isConfirmed: item.isConfirmed,
          amount: item.amount
        }));
        setRows(data);
      } catch (error) {
        alert('데이터를 불러오는데 실패하였습니다. 잠시 후 다시 시도해주세요.');
      }
    };
    fetchData();
  }, []);

  const getStatusText = (isConfirmed, amount) => {
    if (isConfirmed === false) {
      return '심사중';
    } else if (isConfirmed === true) {
      if (amount !== 0) {
        return '선정';
      } else {
        return '미선정';
      }
    }
  };

  const getActionButtons = (row) => {
    const handlePrint = async () => {
      try {
        const responsePdf = await http.getWithToken(
          `/api/scholarships/download?moduleName=${row.moduleName}`,
          {},
          { responseType: 'blob' }
        );
        const file = new Blob([responsePdf.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '장학증서 다운로드 하기', 'height=1000, width=800');
      } catch (error) {
        alert('장학증서 pdf 다운로드에 실패하였습니다. 잠시 후 다시 시도해주세요.');
      }
    };

    if (row.isConfirmed === false) {
      return null;
    } else if (row.isConfirmed === true && row.amount !== 0) {
      return (
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" size="small" onClick={handlePrint}>
            장학증서발급
          </Button>
        </Box>
      );
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Paper sx={{ width: '85%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#bfbfbf' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                      현재 목록이 비어있습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.moduleName}-${row.year}-${row.semester}-${row.isConfirmed}-${index}`}
                      >
                        {columns.map((column) => {
                          let value;
                          if (column.id === 'action') {
                            value = getActionButtons(row);
                          } else if (column.id === 'process') {
                            if (row[column.id] === 'LOW') {
                              value = '초급';
                            } else if (row[column.id] === 'MIDDLE') {
                              value = '중급';
                            } else if (row[column.id] === 'HIGH') {
                              value = '고급';
                            } else {
                              value = row[column.id];
                            }
                          } else {
                            value = row[column.id];
                          }

                          return (
                            <TableCell key={`${row.moduleName}-${column.id}`} align={column.align}>
                              {column.id === 'isConfirmed' ? getStatusText(row.isConfirmed, row.amount) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
