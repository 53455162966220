import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import SubjectCard from './SubjectCard';
import SubjectListCard from './SubjectListCard';
import { Http } from 'api/http/http';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/reducers/auth';

const createData = (name, moduleNames, standardSubjects, lectureNames) => ({ name, moduleNames, standardSubjects, lectureNames });

const getRows = (dataInput) => {
  const courseData = {
    '초급 과정': { moduleNames: [], standardSubjects: [], lectureNames: [] },
    '중급 과정': { moduleNames: [], standardSubjects: [], lectureNames: [] },
    '고급 과정': { moduleNames: [], standardSubjects: [], lectureNames: [] }
  };

  dataInput.forEach((item) => {
    const processName = {
      HIGH: '고급 과정',
      MIDDLE: '중급 과정',
      LOW: '초급 과정'
    }[item.process];

    if (!courseData[processName].moduleNames.includes(item.moduleName)) {
      courseData[processName].moduleNames.push(item.moduleName);
      courseData[processName].standardSubjects.push([]); //
      courseData[processName].lectureNames.push([]);
    }

    const moduleIndex = courseData[processName].moduleNames.indexOf(item.moduleName);
    courseData[processName].lectureNames[moduleIndex] =
      item.subjectList.length > 1
        ? [
          ...courseData[processName].lectureNames[moduleIndex],
          item.subjectList.reduce((acc, subject) => acc + ',' + subject.subjectName, item.standardSubject)
        ]
        : item.credit === 2
          ? [...courseData[processName].lectureNames[moduleIndex], item.subjectList[0].subjectName + '(2학점)']
          : [...courseData[processName].lectureNames[moduleIndex], item.subjectList[0].subjectName];

    courseData[processName].standardSubjects[moduleIndex] = [
      ...courseData[processName].standardSubjects[moduleIndex],
      item.standardSubject
    ];
  });

  const rows = Object.entries(courseData).map(([name, data]) =>
    createData(name, data.moduleNames, data.standardSubjects, data.lectureNames)
  );

  return rows;
};

const MyTable = () => {
  const [selected, setSelected] = useState({ selectedLectures: [], module: '', changed: false, credit: 0 });
  const [apiData, setApiData] = useState(null);
  const [completedSubjects, setCompletedSubjects] = useState(null);
  const [completedModules, setCompletedModules] = useState(null);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const { major } = useSelector((state) => state.auth);
  const isMajorStem = major === 'STEM' ? true : false;

  const fetchData = async () => {
    try {
      const response = await http.getWithToken('/api/modules');
      const rawApiData = response.data.data;
      setApiData(rawApiData);
    } catch (error) {
      console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await http.getWithToken('/api/completed-subjects');

      const rawApiData = response.data.data;
      // console.log(rawApiData);
      setCompletedSubjects(rawApiData);
    } catch (error) {
      console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
    }
  };

  const fetchModuleData = async () => {
    try {
      const response = await http.getWithToken('/api/completed-modules');
      const rawApiData = response.data.data;
      setCompletedModules(rawApiData);
    } catch (error) {
      console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
    }
  };

  const getSubjectCode = (data, userSubjectName) => {
    let subjectCode;
    data.some((item) => {
      const foundSubject = item.subjectList.find((subject) => subject.subjectName === userSubjectName);
      if (foundSubject) {
        subjectCode = foundSubject.subjectGroupId;
        return true;
      }
    });
    return subjectCode;
  };

  const getSubjectCodeToSend = (userData, subjectCode) => {
    let subjectCodeToSend;
    userData.forEach((item) => {
      if (item.subjectGroupId === subjectCode) {
        subjectCodeToSend = item.pastSubjectGroupId;
      }
    });
    return subjectCodeToSend;
  };

  const isModuleInApplication = (data = null, moduleName) => {
    if (data) {
      return data.some((module) => module.moduleName === moduleName && module.status !== 'REFUSAL');
    }
    return false;
  };

  const isCompletedModule = (data = null, moduleName) => {
    if (data) {
      return data.some((module) => module.moduleName === moduleName && module.status === 'APPROVAL');
    }
    return false;
  };

  useEffect(() => {
    fetchData();
    fetchUserData();
    fetchModuleData();
  }, []);

  useEffect(() => {
    if (apiData !== null) {
      const changedRows = getRows(apiData);
      setRows(changedRows);
    }
  }, [apiData]);

  const requestIssueHandler = async (moduleName) => {
    if (selected.credit < 12) {
      alert('12학점 이상만 신청할 수 있습니다.');
    } else {
      if (selected.module !== moduleName) {
        alert('선택한 과목의 모듈에 발급신청을 해주세요.');
      } else {
        const selectedGroupId = [];

        selected.selectedLectures.forEach((subject) => {
          const subjectName = subject.includes('2학점') ? subject.replace('(2학점)', '').trim() : subject;
          const subjectCode = getSubjectCode(apiData, subjectName);
          const subjectCodeToSend = getSubjectCodeToSend(completedSubjects, subjectCode);
          selectedGroupId.push(subjectCodeToSend);
        });

        const moduleToCertificate = {
          moduleName: selected.module,
          subjectGroupIdList: selectedGroupId
        };

        try {
          await http.postWithToken('/api/certificates', moduleToCertificate);
          setSelected({ selectedLectures: [], module: '', changed: false, credit: 0 });
          handleModalOpen();
        } catch (error) {
          alert('신청에 실패하였습니다. 다시 시도해주세요.');
        }
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/student-certificate-management');
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">과정</TableCell>
              <TableCell align="center">모듈명</TableCell>
              <TableCell align="center">표준 교과목 명</TableCell>
              <TableCell align="center">수강 교과목 명</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) =>
              row.moduleNames.flatMap((module, moduleIndex) =>
                row.lectureNames[moduleIndex].map((lecture, lectureGroupIndex) => (
                  <TableRow key={`${index}-${moduleIndex}-${lectureGroupIndex}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {moduleIndex === 0 && lectureGroupIndex === 0 && (
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        rowSpan={row.moduleNames.reduce((sum, _, i) => sum + row.lectureNames[i].length, 0)}
                      >
                        {row.name}
                      </TableCell>
                    )}
                    {lectureGroupIndex === 0 && (
                      <TableCell align="center" rowSpan={row.lectureNames[moduleIndex].length} sx={{ borderY: 'none' }}>
                        <Typography>{module}</Typography>
                        <br />
                        <Box>
                          {isMajorStem ? (
                            module === '비이공계' ? (
                              <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                                비이공계열만 신청가능
                              </Button>
                            ) : isModuleInApplication(completedModules, module) ? (
                              isCompletedModule(completedModules, module) ? (
                                <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                                  발급완료
                                </Button>
                              ) : (
                                <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                                  발급신청완료
                                </Button>
                              )
                            ) : (
                              <Button variant="contained" onClick={() => requestIssueHandler(module)}>
                                발급신청
                              </Button>
                            )
                          ) : module === '이공계' ? (
                            <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                              이공계열만 신청가능
                            </Button>
                          ) : isModuleInApplication(completedModules, module) ? (
                            isCompletedModule(completedModules, module) ? (
                              <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                                발급완료
                              </Button>
                            ) : (
                              <Button sx={{ marginLeft: '10px' }} variant="contained" disabled>
                                발급신청완료
                              </Button>
                            )
                          ) : (
                            <Button variant="contained" onClick={() => requestIssueHandler(module)}>
                              발급신청
                            </Button>
                          )}
                        </Box>
                        <br />
                        {module === selected.module && selected.credit !== 0 ? (
                          <Typography>{selected.credit}학점 선택</Typography>
                        ) : (
                          <Typography></Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center" style={{ wordWrap: 'break-word', width: '24rem' }}>
                      {row.standardSubjects[moduleIndex][lectureGroupIndex]}
                    </TableCell>
                    <TableCell align="center" style={{ wordWrap: 'break-word', width: '24rem' }}>
                      {lecture.includes(',') ? (
                        <SubjectListCard
                          lecture={lecture}
                          currentModule={module}
                          selected={selected}
                          addLecture={setSelected}
                          wholeSubjects={apiData}
                          completedSubjects={completedSubjects}
                        />
                      ) : (
                        <SubjectCard
                          title={lecture}
                          currentModule={module}
                          selected={selected}
                          addLecture={setSelected}
                          wholeSubjects={apiData}
                          completedSubjects={completedSubjects}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={modalOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'모듈 발급 신청 완료'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#272727', fontSize: '1rem', margin: '5px', mt: '20px' }}>
            모듈 발급 신청이 완료 되었습니다
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleModalClose}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyTable;
