// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  AuditOutlined,
  LoginOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  HomeOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  AuditOutlined,
  LoginOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  HomeOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const StudentPage = {
  id: 'studentPage',
  title: 'StudentPage',
  type: 'group',
  children: [
    {
      id: 'studentHome',
      title: '홈',
      type: 'item',
      url: '/studentHome',
      icon: icons.HomeOutlined
    },
    {
      id: 'studentMoudule',
      title: '교과목 이수현황',
      type: 'item',
      url: '/studentMoudule',
      icon: icons.CheckCircleOutlined
    },
    {
      id: 'studentcertificate-page',
      title: '이수증 관리',
      type: 'item',
      url: '/student-certificate-management',
      icon: icons.AuditOutlined
    },
    {
      id: 'studentscholarship certificate-page',
      title: '장학증서 관리',
      type: 'item',
      url: '/student-scholarship-certificate-management',
      icon: icons.IdcardOutlined
    },
    {
      id: 'studentInquiry',
      title: '문의',
      type: 'item',
      url: '/studentInquiry',
      icon: icons.QuestionCircleOutlined
    }
  ]
};

export default StudentPage;
