import { Navigate } from 'react-router-dom';

// project import

import MainLayout from 'layout/MainLayout';
import AdminCertificate from 'pages/adminPages/adminCertificate';
import AdminScholarship from 'pages/adminPages/adminScholarship';
import StudentCertificate from 'pages/studentPages/studentCertificate';
import StudentScholarship from 'pages/studentPages/studentScholarship';
import StudentHome from 'pages/studentPages/StudentHome';
import StudentModule from 'pages/studentPages/StudentModule';
import StudentInquiry from 'pages/studentPages/StudentInquiry';
import AdminHome from 'pages/adminPages/AdminHome';
import AdminModule from 'pages/adminPages/AdminModule';

import AdminCertificateAdd from 'pages/adminPages/adminCertificateAdd';
import AdminScholarshipAdd from 'pages/adminPages/adminScholarshipAdd';
import ProtectedRoute from './ProtectedRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import AdminStudentInfo from 'pages/adminPages/AdminStudentInfo';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: 'student-certificate-management',
          element: <StudentCertificate />
        },
        {
          path: 'student-scholarship-certificate-management',
          element: <StudentScholarship />
        },
        {
          path: 'studentHome',
          element: <StudentHome />
        },
        {
          path: 'studentMoudule',
          element: <StudentModule />
        },
        {
          path: 'studentInquiry',
          element: <StudentInquiry />
        }
      ]
    },
    {
      path: '/',
      element: <ProtectedAdminRoute />,
      children: [
        {
          path: 'admin-certificate-management',
          element: <AdminCertificate />
        },
        {
          path: 'admin-certificate-add-management',
          element: <AdminCertificateAdd />
        },
        {
          path: 'admin-scholarship-certificate-management',
          element: <AdminScholarship />
        },
        {
          path: 'admin-scholarship-certificate-add-management',
          element: <AdminScholarshipAdd />
        },
        {
          path: 'adminHome',
          element: <AdminHome />
        },
        {
          path: 'adminModule',
          element: <AdminModule />
        },
        {
          path: 'student-info',
          element: <AdminStudentInfo />
        }
      ]
    },
    {
      path: '*', // 최상위 레벨에서 모든 정의되지 않은 경로를 매치
      element: <Navigate to="/studentLogin" replace /> // studentLogin으로 리다이렉트
    }
  ]
};

export default MainRoutes;
