import axios from 'axios';

const baseUrl = 'https://kwshareservice.com';
// const baseUrl = 'https://kwhcclab.com:29081';

const REFRESH_URL = '/api/refresh';

export class Http {
  constructor({ baseURL = baseUrl, isLoginAPI = false, onRefreshExpired, onLogout } = {}) {
    const axiosConfig = {
      baseURL,
      withCredentials: true
    };

    this.httpClient = axios.create(axiosConfig);
    this.onRefreshExpired = onRefreshExpired;
    this.onLogout = onLogout;
    this.isLoginAPI = isLoginAPI;
    this.isRefreshing = false;

    //요청 인터셉터
    this.httpClient.interceptors.request.use(
      (request) => {
        // 토큰을 가져옴
        const accessToken = sessionStorage.getItem('accessToken');

        if (!this.isLoginAPI) {
          request.headers['Content-Type'] = 'application/json';
          request.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return request;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    // 응답 인터셉터
    this.httpClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config, response } = error;
        // 만료된 상태인 경우
        if (response.status === 401 && !config._retry && !this.isRefreshing) {
          config._retry = true;
          this.isRefreshing = true;
          try {
            // refresh 토큰으로 요청을 보낸 후 AccessToken 갱신
            const refreshResponse = await this.httpClient.post(REFRESH_URL);
            const newAccessToken = refreshResponse.data.data[0].accessToken;
            sessionStorage.setItem('accessToken', newAccessToken);

            // 새로운 토큰으로 요청 재시도
            config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            this.isRefreshing = false;
            return this.httpClient(config);
          } catch (refreshError) {
            // 리프레쉬 토큰도 만료되었을 경우 처리
            if (this.onRefreshExpired && onLogout) {
              this.isRefreshing = false;
              console.error('세션이 만료되었습니다. 다시 로그인해주세요');
              this.onLogout();
              this.onRefreshExpired();
            }
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getWithToken(url, params, config) {
    return this.httpClient.get(url, {
      params,
      ...config
    });
  }

  async postWithToken(url, data, config) {
    return this.httpClient.post(url, data, {
      ...config
    });
  }

  async deleteWithToken(url, data, config) {
    return this.httpClient.delete(url, {
      ...config,
      data: data
    });
  }

  async putWithToken(url, data, config) {
    return this.httpClient.put(url, data, {
      ...config
    });
  }
}
