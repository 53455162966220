import { Container, Grid, Typography, Card, CardMedia, Box } from '@mui/material';
import homeLogo from '../../assets/images/logo/kw_home_logo.jpeg';
import fixLogo from '../../assets/images/logo/fix_logo.png';

const AdminHome = () => {
  return (
    <Container>
      <Grid item xs={12}>
        <Grid container justifyContent="space-around" alignItems="center" rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <Card>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1.5rem' }}>
                <Typography variant="h3">환영합니다 관리자님</Typography>
                <img style={{ width: '1.5rem', marginLeft: '0.8rem' }} src={fixLogo} alt="수리 이미지" />
              </Box>
              <CardMedia
                component="img"
                image={homeLogo}
                alt="설명"
                sx={{
                  objectFit: 'contain',
                  height: 200
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminHome;
