import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedLoginRoute() {
  const { isLoggedIn, role } = useSelector((state) => state.auth); // 로그인 여부, 어드민 여부

  if (isLoggedIn && role === 'student') {
    return <Navigate to="/studentHome" replace />;
  }
  if (isLoggedIn && role === 'admin') {
    return <Navigate to="/adminHome" replace />;
  }

  return <Outlet />; // 로그인한 경우, 해당 라우트의 자식 컴포넌트 렌더링
}
