import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText
} from '@mui/material';
import CustomPagination from 'components/pagination';
import { Http } from 'api/http/http';
import { logout } from 'store/reducers/auth';

const columns = [
  { id: 'studentNumber', label: '학번', minWidth: 100, align: 'center' },
  { id: 'studentName', label: '이름', minWidth: 100, align: 'center' },
  { id: 'email', label: '이메일', minWidth: 100, align: 'center' },
  { id: 'phoneNumber', label: '연락처', minWidth: 100, align: 'center' },
  { id: 'scholarshipNumber', label: '발급번호', minWidth: 100, align: 'center' },
  { id: 'year', label: '년도', minWidth: 100, align: 'center' },
  { id: 'semester', label: '학기', minWidth: 100, align: 'center' },
  { id: 'process', label: '과정', minWidth: 100, align: 'center' },
  { id: 'moduleName', label: '모듈명', minWidth: 100, align: 'center' },
  { id: 'amount', label: '장학금액', minWidth: 100, align: 'center' },
  { id: 'totalGrade', label: '총성적', minWidth: 100, align: 'center' },
  { id: 'action', label: 'Action', minWidth: 100, align: 'center' }
];

const scholarshipAmounts = [
  { level: 'SS', amount: 1000000 },
  { level: 'S', amount: 600000 },
  { level: 'A', amount: 500000 },
  { level: 'ZERO', amount: 0 }
];

export default function AdminScholarshipAdd() {
  const rowsPerPage = 20;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [finalOpen, setFinalOpen] = useState(false);

  const [level, setLevel] = useState('');
  const [studentNumberInfo, setStudentNumberInfo] = useState(null);
  const [moduleNameInfo, setModuleNameInfo] = useState(null);

  const [sortConfig, setSortConfig] = useState({ field: '', direction: '' });

  const [moduleFilterSelected, setModuleFilterSelected] = useState('');
  const [moduleFilter, setModuleFilter] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  // 정렬기능: 선택된 컬럼에 대해 정렬순서 토글
  const handleSort = () => {
    const isDesc = sortConfig.field === 'totalGrade' && sortConfig.direction === 'desc';
    setSortConfig({ field: 'totalGrade', direction: isDesc ? 'asc' : 'desc' });
  };

  const handleModuleChange = (event) => setModuleFilterSelected(event.target.value);

  const fetchData = async () => {
    try {
      let url = `/api/admin/scholarships?page=${page}&size=${rowsPerPage}`;

      // 모듈 필터 정볼르 url에 추가
      const moduleFilterParam = moduleFilterSelected || moduleFilter.map((m) => m.id).join(',');
      url += `&moduleFilter=${moduleFilterParam}`;

      // 정렬정보를 url에 추가
      if (sortConfig.field) {
        url += `&sort=${sortConfig.field},${sortConfig.direction}`;
      }

      const response = await http.getWithToken(url);

      setRows(response.data.data.content);
      setTotalPages(response.data.data.totalPages);
    } catch (error) {
      alert('데이터 불러오는데 실패하였습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage - 1);
    }
  };

  // 모듈 필터링 목록 가져오기
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await http.getWithToken('/api/admin/scholarships/filter');
        if (response.status === 200 && response.data.data.length > 0) {
          const filters = response.data.data[0];
          setModuleFilter(filters.moduleFilter);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
      }
    };

    fetchModules();
  }, []);

  // 장학증서 리스트 불러오기
  useEffect(() => {
    fetchData();
  }, [page, moduleFilterSelected, sortConfig, moduleFilter]);

  //  최종승인
  const handleConfirm = async () => {
    try {
      const response = await http.postWithToken('/api/admin/scholarships/confirm-all');

      if (response.data.status === 'SUCCESS') {
        alert('최종 승인에 성공하였습니다.');
        handleFinalClose();
        navigate('/admin-scholarship-certificate-management');
      } else {
        alert('오류가 발생했습니다. 다시 시도해주세요');
        handleFinalClose();
      }
    } catch (error) {
      alert('오류가 발생했습니다. 다시 시도해주세요');
      handleFinalClose();
    }
  };

  const handleClickOpen = async (studentNumber, moduleName) => {
    setStudentNumberInfo(studentNumber);
    setModuleNameInfo(moduleName);
    setOpen(true);
  };

  const handleClose = async () => {
    setStudentNumberInfo(null);
    setModuleNameInfo(null);
    setLevel('');
    setOpen(false);
  };

  const handleFinalOpen = async () => {
    setFinalOpen(true);
  };

  const handleFinalClose = async () => {
    setFinalOpen(false);
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setLevel(value);
  };

  const handleAmountSetting = async () => {
    if (!level) {
      alert('등급을 선택해 주세요.');
      return;
    }

    const amountToSubmit = {
      studentNumber: studentNumberInfo,
      moduleName: moduleNameInfo,
      amount: level
    };

    try {
      const response = await http.putWithToken('api/admin/scholarships/amount', amountToSubmit);

      if (response.data.status === 'SUCCESS') {
        alert('장학금 금액 산정을 성공하였습니다');
        fetchData();
        handleClose();
      } else {
        alert('오류가 발생했습니다. 다시 시도해주세요');
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="50vh">
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '45%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Box sx={{ width: '12%', marginRight: '16px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ paddingY: 0.25 }} id="module-filter-label">
                  모듈
                </InputLabel>
                <Select value={moduleFilterSelected} label="Module" onChange={handleModuleChange}>
                  <MenuItem value="">선택하지 않음</MenuItem>
                  {moduleFilter.map((module) => (
                    <MenuItem key={module.id} value={module.id}>
                      {module.moduleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button variant="contained" color="primary" onClick={handleFinalOpen}>
              최종승인
            </Button>
          </Box>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 1200 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#bfbfbf' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, cursor: column.id === 'totalGrade' ? 'pointer' : 'default' }}
                      onClick={column.id === 'totalGrade' ? handleSort : undefined}
                    >
                      {column.label}
                      {column.id === 'totalGrade' && sortConfig.field === 'totalGrade' && (sortConfig.direction === 'desc' ? ' 🔽' : ' 🔼')}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                      현재 목록이 비어있습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`${row.studentNumber}-${row.moduleName}`}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'action') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClickOpen(row.studentNumber, row.moduleName)}
                                  style={{ marginBottom: '5px' }}
                                  disabled={row['amount'] !== null}
                                >
                                  장학 등급 지정
                                </Button>
                              </TableCell>
                            );
                          } else if (column.id === 'amount') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value !== null ? `${value.toLocaleString()} 원` : ''}
                              </TableCell>
                            );
                          } else if (column.id === 'process') {
                            let course;
                            if (value === 'LOW') {
                              course = '초급';
                            } else if (value === 'MIDDLE') {
                              course = '중급';
                            } else if (value === 'HIGH') {
                              course = '고급';
                            } else {
                              course = value;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {course}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CustomPagination count={totalPages} page={page} onChange={handleChangePage} />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>장학금 등급 지정</DialogTitle>
        <DialogContent>
          <DialogContentText>장학금 등급을 지정할 수 있습니다</DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginX: '96px'
            }}
          >
            <FormControl fullWidth style={{ marginTop: 16 }}>
              <InputLabel sx={{ paddingY: 0.3 }} id="course-select-label">
                등급 선택
              </InputLabel>
              <Select labelId="course-select-label" id="course-select" value={level} label="과정" onChange={handleAmountChange}>
                {scholarshipAmounts.map((scholarship, index) => (
                  <MenuItem key={index} value={scholarship.level}>
                    {`${scholarship.level} 등급 `}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {level ? (
              <p>
                <Typography component="span">선택하신 장학금 등급의 금액은 </Typography>
                <Typography component="span" sx={{ color: 'primary.main' }}>
                  {scholarshipAmounts.find((item) => item.level === level).amount.toLocaleString()}
                </Typography>
                <Typography component="span"> 원 입니다.</Typography>
              </p>
            ) : (
              <p></p>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAmountSetting}>추가</Button>
          <Button onClick={handleClose}>취소</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={finalOpen} onClose={handleFinalClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'전체 장학금 목록 최종 승인'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '1rem', margin: '5px', mt: '20px' }}>
            전체 장학금 목록을 최종 승인하시겠습니까?
          </DialogContentText>
          <DialogContentText sx={{ color: '#d32f2f', fontSize: '0.8rem', margin: '5px' }}>
            등급을 지정하지 않은 신청자들은 ZERO 등급으로 처리 됩니다
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} autoFocus>
            예
          </Button>
          <Button onClick={handleFinalClose}>아니오</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
