import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export default function CertificateCancelPopup({ open, handleClose }) {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disablebackdropclick="true">
      <DialogTitle color="black">이수증 발급신청 취소</DialogTitle>
      <DialogContent>
        <Typography id="alert-dialog-description1" color="grey">
          이수증 모듈 신청을 취소하시겠습니까?
        </Typography>
        <Typography id="alert-dialog-description2" color="grey">
          취소 후에도 해당 모듈 재발급 신청이 가능합니다.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)}>확인</Button>
        <Button onClick={() => handleClose(false)} autoFocus>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
}
