import { useState } from 'react';

const useToggleFirstPassword = (initialVisibility = { password1: false, password2: false }) => {
  const [showPassword, setShowPassword] = useState(initialVisibility);

  const handleClickShowPassword = (password) => {
    setShowPassword({ ...showPassword, [password]: !showPassword[password] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return { showPassword, handleClickShowPassword, handleMouseDownPassword };
};

export default useToggleFirstPassword;
