import { Container } from '@mui/material';
import MyTable from './MyTable';

const StudentModule = () => {
  return (
    <Container>
      <MyTable />
    </Container>
  );
};

export default StudentModule;
