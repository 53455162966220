import React from 'react';
import { Stack, Pagination } from '@mui/material';

export default function CustomPagination({ count, page, onChange }) {
  return (
    <Stack spacing={2} marginTop={4}>
      <Pagination count={count} page={page + 1} onChange={onChange} variant="outlined" shape="rounded" />
    </Stack>
  );
}
