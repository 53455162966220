// material-ui
import { Box } from '@mui/material';

// project import
import Profile from './Profile';
//import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  //const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', ml: 1 }}>
      <Profile />
    </Box>
  );
};

export default HeaderContent;
