import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  isLoggedIn: false, // 로그인 여부
  name: null, // 로그인한 사용자 정보
  role: 'admin', // 사용자 역할 ('student' or 'admin')
  major: null
};

// ==============================|| SLICE - AUTH ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    settingStudentInfo(state, action) {
      state.name = action.payload.name;
      state.role = 'student';
      state.major = action.payload.major;
    },

    loginAsStudent(state, action) {
      state.isLoggedIn = true;
      state.name = action.payload.name;
      state.role = 'student';
      state.major = action.payload.major;
    },

    loginAsAdmin(state) {
      state.isLoggedIn = true;
      state.name = 'admin';
      state.role = 'admin';
    },

    logout(state) {
      state.isLoggedIn = false;
      state.name = null;
      state.role = null;
      state.major = null;
    }
  }
});

export default auth.reducer;

export const { settingStudentInfo, loginAsStudent, loginAsAdmin, logout } = auth.actions;
