import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ProtectedLoginRoute from './ProtectedLoginRoute';

// render - login
const AdminLogin = Loadable(lazy(() => import('pages/authentication/AdminLogin')));
const StudentLogin = Loadable(lazy(() => import('pages/authentication/StudentLogin')));
const StudentFirstPasswordSetupDialog = Loadable(lazy(() => import('pages/authentication/StudentFirstPasswordSetup')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const StudentSubjectsSelection = Loadable(lazy(() => import('pages/authentication/StudentSubjectsSelection')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <ProtectedLoginRoute />,
      children: [
        {
          path: '/',
          element: <Navigate replace to="studentLogin" />
        },
        {
          path: 'studentLogin',
          element: <StudentLogin />
        },
        {
          path: 'adminLogin',
          element: <AdminLogin />
        },
        {
          path: 'student-subjects-selection',
          element: <StudentSubjectsSelection />
        },
        {
          path: 'studentFirstPassword',
          element: <StudentFirstPasswordSetupDialog />
        }
      ]
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: '*', // 최상위 레벨에서 모든 정의되지 않은 경로를 매치
      element: <Navigate to="/studentLogin" replace /> // studentLogin으로 리다이렉트
    }
  ]
};

export default LoginRoutes;
