import { Container } from '@mui/material';
import AdminTable from './AdminTable';

const AdminMoudule = () => {
  return (
    <Container>
      <AdminTable />
    </Container>
  );
};

export default AdminMoudule;
